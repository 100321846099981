import React from "react";
import styles from "./CardPrice.module.css";

function CardPrice() {
  return (
    <div
      className="container"
      style={{ paddingBottom: "5rem", marginTop: "10rem" }}
    >
      <center>
        <h1
          style={{
            color: "white",
            overflow: "hidden",
            fontFamily: "Poppins",
            fontWeight: "bolder",
          }}
          className={styles.textshadows}
        >
          Prices
        </h1>
        <p className={styles.stared}>
          The starting prices are written in the tables below.
        </p>
      </center>
      <div className="row">
        <div className="col-xl-4" style={{ padding: "0rem" }}>
          <section style={{ width: "100%", height: "100%" }}>
            <div
              className={styles.bgforcard}
              style={{
                overflow: "hidden",
                borderRadius: "1rem",
                color: "#FFA500",
                fontWeight: "bolder",
                fontFamily: "Righteous",
                padding: "3rem 2rem 1rem 2rem",
                boxShadow: " 0px 0px 15px 5px rgba(0,0,0,0.1)",
                margin: "2rem",
              }}
            >
              <h1 style={{ fontSize: "30px" }}>
                <center>
                  <span style={{ overflow: "hidden" }} className={styles.spann}>
                    Pro PLAN <br></br>$12,000
                  </span>
                </center>
                <br />
                <br />
                <span
                  style={{
                    color: "white",
                  }}
                >
                  <h6 style={{ fontSize: "20px", overflow: "hidden" }}>
                    ⚡ Complete Mobile Application (Android/iOS)
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Cross-Platform Development (React Native, Flutter)
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Web Application (PWA, Responsive)
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Custom Admin Panel
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Responsive & Adaptive Design
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Advanced UI/UX Design
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ 4 Years Support
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ 1 Year SEO & Content Strategy
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Desktop App Integration
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ API Development (Node.js, MongoDB)
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Performance Optimization
                  </h6>
                </span>
              </h1>
            </div>
          </section>
        </div>
        <div className="col-xl-4" style={{ padding: "0rem" }}>
          <section
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                borderRadius: "1rem",
                color: "#FFA500",
                fontWeight: "bolder",
                fontFamily: "Righteous",
                padding: "3rem 2rem 1rem 2rem",
                boxShadow: " 0px 0px 15px 5px rgba(0,0,0,0.1)",
                margin: "2rem",
                height: "86vh",
              }}
            >
              <h1 style={{ fontSize: "30px" }}>
                <center style={{ overflow: "hidden" }}>
                  Premium PLAN <br></br>$6,000
                </center>
                <br />
                <br />
                <span
                  style={{
                    color: "white",
                  }}
                >
                  <h6 style={{ fontSize: "20px", overflow: "hidden" }}>
                    ⚡ Mobile Application (Android/iOS)
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Web Application (PWA, Responsive)
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Custom Admin Panel
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Responsive & Adaptive Design
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Custom UI/UX Design
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ 2 Years Support
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Basic SEO Implementation
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Database Integration (MongoDB)
                  </h6>
                </span>
              </h1>
            </div>
          </section>
        </div>
        <div className="col-xl-4" style={{ padding: "0rem" }}>
          <section style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                borderRadius: "1rem",
                color: "#FFA500",
                fontWeight: "bolder",
                fontFamily: "Righteous",
                padding: "3rem 2rem 1rem 2rem",
                boxShadow: " 0px 0px 15px 5px rgba(0,0,0,0.1)",
                margin: "2rem",
                height: "86vh",
              }}
            >
              <h1 style={{ fontSize: "30px", overflow: "hidden" }}>
                <center>
                  Basic PLAN<br></br> $2,500
                </center>
                <br />
                <br />
                <span
                  style={{
                    color: "white",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Web Application (PWA)
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Custom Admin Panel
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Responsive Design
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Basic UI/UX
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ 1 Year Support
                  </h6>
                  <h6
                    style={{
                      fontSize: "20px",
                      paddingTop: "1rem",
                      overflow: "hidden",
                    }}
                  >
                    ⚡ Basic SEO Setup
                  </h6>
                </span>
              </h1>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default CardPrice;
