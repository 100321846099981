import React from "react";
import styles from "./Header.module.css";

function Header() {
  return (
    <div>
      <section
        className={styles.all}
        style={
          {
            //   backgroundColor: "#131E25",
          }
        }
      >
        <div>
          <center className="container">
            <h1 className={styles.hone}>LANOVACODE</h1>

            <h5
              style={{
                fontFamily: "Comfortaa",
                fontWeight: "bolder",
                color: "white",
              }}
            >
              company
            </h5>
            <h5
              style={{
                fontFamily: "Comfortaa",
                fontWeight: "bolder",
                fontSize: "13px",
                color: "#FF9F29",
              }}
            >
              We are creating a new world
            </h5>
          </center>
          <div style={{ paddingTop: "10vh" }}>
            <span
              style={{
                color: "white",

                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                margin: "auto",
              }}
            >
              <div className={styles.iconscroll} />
            </span>
            <center>
              <h4
                style={{
                  color: "white",
                  fontSize: "1.3vh",
                  marginTop: "1rem",
                }}
              >
                SCROLL DOWN TO SEE MORE
              </h4>
            </center>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Header;
