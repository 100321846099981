import React from "react";
import styles from "./Afterheader.module.css";
import SliderLogos from "../SliderLogos";

function AfterHeader() {
  return (
    <center>
      <section className={styles.all}>
        <div className="container" style={{ paddingBottom: "1rem" }}>
          <center>
            <h1
              style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                marginTop: "10vh",
                marginBottom: "2rem",
                overflow: "hidden",
                padding: "1rem",
              }}
            >
              <span className={styles.howcan}> How Can We Help Your Brand</span>
            </h1>
            <div style={{ marginBottom: "2rem" }}>
              <SliderLogos />
            </div>
            {/* <img src="./rockett.gif" style={{ height: "20vh" }} /> */}
          </center>
          <div className="row">
            <div className="col-lg">
              <div
                style={{
                  color: "white",
                  // backgroundColor: "#131E25",
                  boxShadow: "0px 7px 15px 8px rgba(0,0,0,0.1)",
                  borderRadius: "2rem",
                  padding: "1rem",
                  margin: "0.5rem",
                  overflow: "hidden",
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    color: "#D01C4E",
                  }}
                >
                  Mobile Application
                  <br></br>
                  <br></br>
                  <h6 style={{ fontWeight: "Poppins", color: "#fff" }}>
                    Android / iOS Development
                  </h6>
                  <h6 style={{ fontWeight: "Poppins", color: "#fff" }}>
                    Cross-Platform Expertise
                  </h6>
                  <h6 style={{ fontWeight: "Poppins", color: "#fff" }}>
                    Custom UI/UX Design
                  </h6>
                  <h6 style={{ fontWeight: "Poppins", color: "#fff" }}>
                    High Performance & Scalability
                  </h6>
                </h3>
              </div>
            </div>
            <div className="col-lg">
              <div
                style={{
                  color: "white",
                  backgroundColor: "#131E25",
                  boxShadow: "0px 7px 15px 8px rgba(0,0,0,0.1)",
                  borderRadius: "2rem",
                  padding: "1rem",
                  margin: "0.5rem",
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    color: "#D61C4E",
                  }}
                >
                  Web Application
                  <br></br>
                  <br></br>
                  <h6 style={{ fontWeight: "Poppins", color: "#fff" }}>
                    Progressive Web Apps (PWAs)
                  </h6>
                  <h6 style={{ fontWeight: "Poppins", color: "#fff" }}>
                    Responsive & Adaptive Design
                  </h6>
                  <h6 style={{ fontWeight: "Poppins", color: "#fff" }}>
                    Custom Web Solutions
                  </h6>
                  <h6 style={{ fontWeight: "Poppins", color: "#fff" }}>
                    Full-Stack Development
                  </h6>
                </h3>
              </div>
            </div>
            <div className="col-lg">
              <div
                style={{
                  color: "white",
                  backgroundColor: "#131E25",
                  boxShadow: "0px 7px 15px 8px rgba(0,0,0,0.1)",
                  borderRadius: "2rem",
                  padding: "1rem",
                  margin: "0.5rem",
                  color: "#D61C4E",
                }}
              >
                <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>
                  SEO & Optimization
                  <br></br>
                  <br></br>
                  <h6 style={{ fontWeight: "Poppins", color: "white" }}>
                    SEO Content Strategy
                  </h6>
                  <h6 style={{ fontWeight: "Poppins", color: "white" }}>
                    Technical Site Optimization
                  </h6>
                  <h6 style={{ fontWeight: "Poppins", color: "white" }}>
                    Analytics & Performance Tracking
                  </h6>
                  <h6 style={{ fontWeight: "Poppins", color: "white" }}>
                    Search Engine Rankings Boost
                  </h6>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </center>
  );
}

export default AfterHeader;
