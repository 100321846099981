import React from "react";
import styles from "./Footer.module.css";

function Footer() {
  return (
    <div>
      <section
        style={{
          color: "white",
          textDecoration: "none",
          //   backgroundColor: "black",
          boxShadow: "0px 10px 7px 7px rgba(0,0,0,0.1)",
          position: "relative",
        }}
      >
        <div>
          <div
            className="container"
            style={{ paddingLeft: "3rem", paddingBottom: "5rem" }}
          >
            <h1
              style={{
                fontWeight: "bolder",
                fontFamily: "Poppins",
                overflow: "hidden",
              }}
            >
              Our Mission 🚀
            </h1>
            <p
              style={{
                fontSize: "160%",
                maxWidth: "1000px",
                fontFamily: "Poppins",
              }}
            >
              At Lanovacode, our mission is to innovate and build cutting-edge
              software solutions that empower businesses to reach new heights.
              We’re committed to delivering world-class mobile apps, websites,
              and seamless digital experiences with the best technology stack
              available.
            </p>
          </div>
          <div className="container">
            <center
              className="row container"
              style={{ margin: "", paddingBottom: "2rem" }}
            >
              <p style={{ textAlign: "left", paddingLeft: "3vh" }}>
                © Lanovacode 2024 - Empowering businesses through innovative
                software solutions.
              </p>

              <a
                className="col-lg-2"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontWeight: "bolder",
                  backgroundColor: "#2C3639",
                  margin: "0.5rem",
                }}
                href="https://wa.me/yourwhatsapplink"
                rel="noopener"
                target="_blank"
              >
                ☎️ Whatsapp
              </a>

              <a
                className="col-lg-2"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontWeight: "bolder",
                  backgroundColor: "#2C3639",
                  margin: "0.5rem",
                }}
                href="https://t.me/yourtelegramlink"
                rel="noopener"
                target="_blank"
              >
                🔔 Telegram
              </a>
              <a
                className="col-lg-2"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontWeight: "bolder",
                  backgroundColor: "#2C3639",
                  margin: "0.5rem",
                }}
                href="mailto:info@lanovacode.com"
                rel="noopener"
                target="_blank"
              >
                📧 Email
              </a>
              <a
                className="col-lg-2"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontWeight: "bolder",
                  backgroundColor: "#2C3639",
                  margin: "0.5rem",
                }}
                href="https://www.instagram.com/lanovacode"
                rel="noopener"
                target="_blank"
              >
                📸 Instagram
              </a>
            </center>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
